import { useState, useEffect, useRef } from "react";
// import * as Screens from "./screens";
// import * as Components from "./components";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";

import { createBrowserHistory } from "history";

import { Provider, useSelector, useDispatch } from "react-redux";

import _store from "./redux/store";

import useScreen from "./hooks/useScreen";

import { Typography, Modal } from "antd";

import * as actions from "./redux/actions/auth";

import { DownloadOutlined } from "@ant-design/icons";

import api from "./api";

import useNotification from "./hooks/useNotification";

import NotFound from "./components/NotFound";
import Navigator from "./components/Navigator";
import Splash from "./components/Splash";
import Login from "./screens/Auth/Login";
import Signup from "./screens/Auth/Signup";
import Wallet from "./screens/Wallet";
import DetailProfile from "./screens/DetailProfile";
import User from "./screens/User";
import MyContract from "./screens/MyContract";
import Withdraw from "./screens/Withdraw";
import Contract from "./screens/Contract";
import Cskh from "./screens/CSKH/Cskh";
import Vay from "./screens/Vay";
import Verify from "./screens/Verify";
import Home from "./screens/Home";
import Notifications from "./screens/Notifications";
import History from "./screens/History";

const history = createBrowserHistory();

function App() {
  const { width } = useScreen();
  return (
    <>
      <Provider store={_store}>
        {/* {width <= 1024 ? (
          <Router history={history}>
            <Routers />
          </Router>
        ) : (
          <NotFound />
        )} */}
        <Router history={history}>
          <Routers />
        </Router>
      </Provider>
    </>
  );
}

const Routers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const localtion = useLocation();
  const { status, accessToken } = useSelector((state) => state._auth);
  const [token] = useState(localStorage.getItem("access_token"));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (token) {
          const { data } = await api.get("/users/profile");
          dispatch(actions.initialLogin(data.data));
          dispatch({
            type: "UPDATE_KYC",
            payload: { ...(data?.data?.kyc || {}) },
          });
        }
      } catch (err) { }
    })();
  }, [status, token]);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1600);
    return () => clearTimeout(timeout);
  }, [status, token]);

  useEffect(() => {
    if (token) {
      if (localtion.pathname === "/auth" || localtion.pathname === "/auth/signup") {
        history.push("/")
      }
    }
    else {
      if (localtion.pathname === "/auth") history.push('/auth')
      if (localtion.pathname === "/auth/signup") history.push('/auth/signup')
      else history.push('/auth')
    }
  }, [localtion.pathname, status]);

  useEffect(() => {
    (async () => {
      try {
        if (window?.localStorage.getItem("access_token")) {
          const { data } = await api.get("/users/profile");
          dispatch(actions.initialLogin(data.data));
          dispatch({
            type: "UPDATE_KYC",
            payload: { ...(data?.data?.kyc || {}) },
          });
        }
      } catch (err) { }
    })();
  }, [localtion.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [localtion.pathname]);

  return (
    <><div style={{ paddingBottom: 80 }}>

      <Switch>
        <Route path="/auth" component={Login} exact />
        <Route path="/auth/signup" component={Signup} exact />
        <Route path="/wallet" component={Wallet} exact />
        <Route
          path="/detail-profile"
          component={DetailProfile}
          exact
        />
        <Route path="/history" component={History} exact />
        <Route path="/me" component={User} exact />
        <Route
          path="/my-contract"
          component={MyContract}
          exact
        />
        <Route path="/withdraw" component={Withdraw} exact />
        <Route path="/contracts" component={Contract} exact />
        <Route path="/cskh" component={Cskh} exact />
        <Route path="/vay" component={Vay} exact />
        <Route path="/verify" component={Verify} exact />
        <Route path="/" component={Home} exact />
        <Route
          path="/notifications"
          component={Notifications}
          exact
        />
      </Switch>

      {["/", "/wallet", "/services", "/me", "/vay", "/cskh"].includes(
        localtion.pathname
      ) && (
          <div
            style={{
              position: "fixed",
              zIndex: 1000,
              bottom: 0,
              minWidth: "100%",
            }}
          >
            <Navigator />
          </div>
        )}
    </div>
    </>
  );
};

export default App;
