import {
  AlertFilled,
  CheckOutlined,
  CustomerServiceFilled,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  AuditOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Image, Modal, Table, Typography } from "antd";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../api";
// import ProfileBanner from '../../assets/profileBanner.jpg';
// import tickAsset from '../../assets/tick.png';

import * as actions from "../../redux/actions/auth";
import "../../css/screens/User/User.scss";
import { LOAN_RATE } from "../../utils/constant";
import { currencyFormatVND } from "../../utils/CurrencyFormat";
import { useIntl } from "react-intl";
import ContractForm from "../../components/ContractForm";
export default function User() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [contract, setContract] = useState({});
  const [modal, setModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [pay, setPay] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/contracts");
      if (data.data.length > 0) {
        setContract(data.data[0]);
        setPay(true);
      }
    })();
  }, []);

  const handleClick = () => {
    setModal(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      {modal === true ? (
        <ContractForm profile={profile} data={contract} setModal={setModal} />
      ) : (
        ""
      )}
      <div className="header-container header_signup bg-[#141ed2] p-4">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: "#fff" }}
        >
          {"Thông tin cá nhân"}
        </Typography.Text>
        <div></div>
      </div>
      <div className="avatar flex flex-col items-center">
        <Avatar
          size={120}
          src={profile?.kyc?.id_with_face || profile?.avatar}
        />
        <Image src={""} preview={false} style={{ width: "100%" }} />
        <Typography.Text strong style={{ fontSize: 23, color: "#121212" }}>
          {profile?.kyc?.name}
        </Typography.Text>
        <Typography.Text
          style={{ fontSize: 18, color: "#666", fontWeight: 500 }}
        >
          {profile?.phone}
        </Typography.Text>
      </div>
      {/* {(!profile?.verifiedBankInfo || !profile?.verifiedContactInfo || !profile?.verifiedPersonalInfo ) &&  (
        <AlertVerify status={profile?.kyc?.status} />
      )} */}
      <div style={{ padding: 20 }}>
        <Tab
          title={"Thông tin cá nhân"}
          icon={
            <UserOutlined
              style={{ color: "#fff", fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => history.push("/detail-profile")}
        />

        <Tab
          title={"Thông tin hợp đồng"}
          icon={
            <DollarCircleOutlined
              style={{ color: "#fff", fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => history.push("/my-contract")}
        />

        {pay === true ? (
          <Tab
            title={"Thông tin khoản vay"}
            icon={
              <AuditOutlined
                style={{ color: "#fff", fontSize: 25, marginRight: 20 }}
              />
            }
            onClick={() => setShowDetail(true)}
          />
        ) : null}
        {/* <Tab
          title={('Đăng xuất')}
          icon={
            <LogoutOutlined
              style={{ color: '#fff', fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => dispatch(actions.Logout())}
        /> */}

        <Tab
          title={"Liên hệ bộ phận thẩm định"}
          icon={
            <DollarCircleOutlined
              style={{ color: "#fff", fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => history.push("/cskh")}
        />
        <motion.div
          whileTap={{ scale: 0.95, opacity: 0.4 }}
          className="log-out"
        >
          <Button
            className="log-out-btn"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={<LogoutOutlined style={{ fontSize: 25, color: "#fff" }} />}
            onClick={() => dispatch(actions.Logout())}
          >
            Đăng xuất
          </Button>
        </motion.div>
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="flex gap-2 items-center">
          <Image
            src={"/image/tick.e14cb23e178b8d3acd8f34.png"}
            preview={false}
            style={{ maxWidth: 100 }}
          />
          <Image
            src={"/image/tick.e14cb23e178b8d3acd8f.png"}
            preview={false}
            style={{ maxWidth: 100 }}
          />
        </div>

        <Typography.Text
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#555",
            textAlign: "center",
          }}
        >
          &reg;{" "}
          {"Bản quyền thuộc về Tập Đoàn Dầu Khí Việt Nam | PVFC. "}
          <br />
          Tất cả các quyền được bảo hộ.
          <br />
          Copyright © 2023 PVFC
        </Typography.Text>
      </div>
      <ModalDetailPayment
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        times={contract.times}
        amount={contract.amount}
      />
    </motion.div>
  );
}

const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: "1px solid rgba(0, 106, 176,0.3)",
      }}
      onClick={onClick}
      className="tab"
    >
      {icon}
      <Typography.Text className="tab-text" style={{ color: "#fff" }}>
        {title}
      </Typography.Text>
    </motion.div>
  );
};
const AlertVerify = ({ status }) => {
  const history = useHistory();
  const intl = useIntl();

  return (
    <div className="alert-container">
      <div>
        <Typography.Text strong style={{ fontSize: 17, color: "#121212" }}>
          {"Xác thực tài khoản"}
        </Typography.Text>
        <div
          style={{
            borderBottom: "1px solid rgba(0, 106, 176,0.3)",
            height: 0.1,
            width: "100%",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "30px 10px",
          }}
        >
          <div
            style={{
              flex: 1,
              minWidth: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AlertFilled style={{ fontSize: 45, color: "#242ba6" }} />
          </div>
          <div>
            <Typography.Text
              style={{ fontSize: 16, fontWeight: 400, color: "#121212" }}
            >
              {`Bổ sung CMND/CCCD và chân dung để hoàn tất định
                            danh
                          `}
            </Typography.Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          {status === "pending" && (
            <motion.div whileTap={{ scale: 0.9, opacity: 0.3 }}>
              <Typography.Text
                strong
                style={{
                  textAlign: "right",
                  fontWeight: "700",
                  color: "#e8a922",
                  fontSize: 17,
                }}
              >
                Đang chờ xác minh
              </Typography.Text>
            </motion.div>
          )}
          {(!status || status !== "pending") && (
            <motion.div
              whileTap={{ scale: 0.9, opacity: 0.3 }}
              onClick={() => history.push("/verify")}
            >
              <Typography.Text
                strong
                style={{
                  textAlign: "right",
                  fontWeight: "700",
                  color: "#0022ba",
                  fontSize: 17,
                }}
              >
                {"Xác minh ngay"}
              </Typography.Text>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalDetailPayment = ({
  visible,
  onCancel,
  times,
  amount,
  created_at,
}) => {
  const array = [...new Array(times)].map((item, index) => ({
    index: index + 1,
    amount: amount / times + (amount * LOAN_RATE) / 100,
    period: `${moment(created_at)
      .add(index + 1, "month")
      .format("DD-MM-YYYY")}`,
  }));
  const columns = [
    {
      title: "Kỳ",
      dataIndex: "index",
      key: "index",
      render: (text) => <Typography.Text>Kỳ thứ {text}</Typography.Text>,
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <Typography.Text strong>
          {currencyFormatVND(Math.ceil(text))}
        </Typography.Text>
      ),
    },
    {
      title: "Ngày đóng",
      dataIndex: "period",
      key: "period",
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
  ];
  return (
    <Modal
      visible={visible}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      closeIcon={() => <></>}
      title={"Chi tiết trả nợ"}
    >
      <div style={{ maxHeight: 350, overflowY: "scroll" }}>
        <Table dataSource={array} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};
