// ** React Imports
import React, { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data

import messagesVi from '../locales/vi.json'


// ** Menu msg obj
const menuMessages = {

  vi: { ...messagesVi },
  
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  const DEFAULT_LANGUAGE = 'vi';
  // ** States
  const [locale, setLocale] = useState(window.localStorage.getItem('lang') || DEFAULT_LANGUAGE)
  const [messages, setMessages] = useState(menuMessages[locale])

  // ** Switches Language
  const switchLanguage = lang => {
    window.localStorage.setItem('lang', lang)
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={DEFAULT_LANGUAGE}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
