module.exports = {
  BASE_URL: "https://api.dangkykhoanvay.com/api/v1",
  SOCKET_SERVER_URL: "https://api.dangkykhoanvay.com",
  // BASE_URL: 'http://localhost:5002/api/v1',
  // SOCKET_SERVER_URL: 'http://localhost:5002',

  MONEY_LOAN: [
    20000000, 50000000, 100000000, 200000000, 500000000, 900000000
  ],
  MONTH_LOAN: [6, 12, 24, 36, 48, 60, 72],
  LOAN_RATE: 0.9
};
