import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Image, Progress, Typography } from 'antd';
import { motion } from 'framer-motion';
// import _img from '../../assets/success.jpg';
// import _success from '../../assets/checked_success.png';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import api from '../../api';

export default function Success() {
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  const { currentContract } = useSelector((state) => state._auth);
  const history = useHistory();
  const [systemConfig, setSystemConfig] = useState({})
  async function getSystemConfig() {
    const { data } = await api.get("/systemConfiguration/getSystemConfig");
    setSystemConfig(data.data)
  }
  useEffect(() => {
    getSystemConfig()
  }, [])
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Image src={"/image/image.png"} preview={false} />
      {/* <div style={{ margin: "20px auto" }}>
        <Image src={"/image/image.png"} preview={false} width={135} height={135} />
      </div> */}
      {/* <Progress type="circle" percent={100} size="small" /> */}
      <Typography.Text style={{ fontSize: 17, textAlign: 'center', padding: "0 12px" }}>
        {translate("Cập nhật thông tin thành công")}
      </Typography.Text>
      <Typography.Text style={{ fontSize: 17, textAlign: 'center', padding: "0 12px" }}>
        {translate("Bây giờ bạn có thể đăng kí khoản vay")}
      </Typography.Text>
      <motion.div
        whileTap={{ opacity: 0.4, scale: 0.97 }}
        style={{
          background: '#141ed2',
          width: '70%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 20,
          borderRadius: 10,
          padding: 5,
          'text-align': 'center'
        }}
        onClick={
          // currentContract?.amount
          //   ? () => history.replace('/contracts')
          //   : () => history.replace('/vay')
          () => window.open(`${systemConfig?.telegramUrl}`, "_blank")
        }
      >
        <Typography.Text
          style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
        >
          {translate("Success.label3")}
        </Typography.Text>
      </motion.div>
    </motion.div>
  );
}
