import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import {
  Typography,
  Image,
  Spin,
  Progress,
  Button,
} from 'antd';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import converter from '../../utils/converterBase64ToBinary';
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
import api from '../../api';
// import _img from '../../assets/contract_success.jpg';
// import _success from '../../assets/checked_success.png';
import connectCSKH from '../../utils/connectCSKH';
import useNotification from '../../hooks/useNotification';
import { BASE_URL } from '../../utils/constant'
import '../../css/screens/Contract/Contract.scss';
import getContractImage from '../../utils/getContractImage';
import ContractFormNew from '../../components/ContractForm';
import { useIntl } from "react-intl";
import { useCallback } from 'react';

export default function Contract() {
  const intl = useIntl();

  const { pushNotifications } = useNotification();
  const history = useHistory();
  const padRef = useRef();

  const { currentContract, profile } = useSelector((state) => state._auth);
  const [signature, setSignature] = useState(null);
  const [signing, setSigning] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [checked, setChecked] = useState(false);
  const confirmSignature = () => {
    if (padRef.current.isEmpty() === false) {
      setSignature(padRef.current.toDataURL());
      setSigning(false);
    }
  };

  const createContract = async () => {
    // if (!checked) {
    //   message.error('Đồng ý với hợp đồng để tiếp tục');
    //   return;
    // }
    setLoading(true);
    let formData = new FormData();
    const dataURI = converter(signature);

    formData.append('file', dataURI);
    setLoading(true);
    const { data } = await axios.post(
      BASE_URL + '/upload',
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    await api.post('/contracts', {
      signature_capture: data.secure_url,
      times: currentContract.times || 6,
      amount: currentContract.amount || 30000000,
    });
    await api.post('/users/init', { initRoute: '/' });

    pushNotifications({
      message: `${profile.phone} gửi yêu cầu vay tiền`,
      description: `Thời hạn ${currentContract?.times
        } tháng, ${currentContract?.amount?.toLocaleString()} ${("")}`,
    });
    setLoading(false);
    setSuccess(true);
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-content header_signup !bg-[#141ed2]] p-4">
        <motion.div

          onClick={() => history.replace('/')}
          style={{ padding: 0 }}
        >
          <LeftOutlined className="left-icon" />
        </motion.div>
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 20, color: 'white' }}
        >
          {("Xác nhận khoản vay")}
        </Typography.Text>
        <div></div>
      </div>
      {success ? (
        <>
          <motion.div
            initial={{ opacity: 0.3, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="success-contract p-4"
          >
            <Image src={"/image/image.png"} preview={false} width={"100%"} />
            {/* <div style={{ margin: "20px auto" }}>
              <Image src={""} preview={false} width={135} height={135} />
            </div> */}
            <Progress type="circle" percent={100} size="small" />
            <Typography.Text className="success-contract-title" style={{ fontWeight: "600", fontSize: "18px", padding: "0 12px" }}>
              {("Tạo khoản vay thành thành công")}
            </Typography.Text>
            <Typography.Text className="success-contract-title mb-3" style={{ fontSize: "16px", padding: "0 12px" }}>
              {"Liên hệ CSKH để duyệt hồ sơ"}
            </Typography.Text>
            <Button className="back-btn " onClick={connectCSKH}>
              LIÊN HỆ CSKH để duyệt hồ sơ
            </Button>
          </motion.div>
        </>
      ) : (
        <>
          {loading ? (
            <div className="loading">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
              <Typography.Text>Đang load</Typography.Text>
            </div>
          ) : (
            <>
              <div className="checking-container">
                {/* <div style={{ marginTop: "20px" }}>
                  <ContractFormNew profile={profile} currentContract={currentContract} signature={signature} />
                </div> */}

                {signing && (
                  <>
                    <Typography.Text>{("Kí vào bên dưới")} </Typography.Text>
                    <div className="signing">
                      <SignatureCanvas
                        ref={padRef}
                        penColor="#666"
                        canvasProps={{
                          width: window.innerWidth - 20 || 300,
                          height: 200,
                          className: 'sigCanvas',
                        }}
                      />
                    </div>

                    <div className="refresh">
                      <Typography.Text
                        onClick={() => padRef.current.clear()}
                        style={{ textDecoration: 'underline' }}
                      >
                        {("Xoá")}{' '}
                      </Typography.Text>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        style={{ background: '#141ed2' }}
                        onClick={confirmSignature}
                      >
                        <Typography.Text style={{ color: '#fff' }}>
                          {"Xác nhận chữ kí"}
                        </Typography.Text>
                      </Button>
                    </div>
                  </>
                )}
                {!signing && (
                  <>
                    <Image src={signature} preview={false} />
                    <div className="create-contract-container">
                      <motion.div
                        whileTap={{ opacity: 0.4, scale: 0.97 }}
                        className="create-contract"
                        onClick={createContract}
                      >
                        <Typography.Text className="create-contract-title">
                          {"Tạo hợp đồng"}
                        </Typography.Text>
                      </motion.div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </motion.div>
  );
}
