/* eslint-disable import/no-anonymous-default-export */
import "../../css/screens/Auth/Login.scss"
import { Image, Typography, Input, Button, message, Form } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as authActions from "../../redux/actions/auth";
// import welcomeImg from "../../assets/welcome.jpg";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { motion } from "framer-motion";
import {
  PhoneOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
export default function Login() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [state, setState] = useState({
    phone: params.get("phone") || "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    history.push("/auth/signup");
  };

  async function onFinish() {
    if (!state.phone || !state.password) {
      message.error("Không được để trống thông tin.");
      return;
    }
    dispatch(authActions.Login(state));
  }

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: ` linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 100%), url('/image/133289620898742966_770-x-740.png')`,

        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="empty-div"
        initial={{
          opacity: 0.4,
          scale: 0.35,
        }}
        transition={{ duration: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}

      >
        <div style={{
          height: "80px",
          width: "200px",
          margin: "45px 0 0"
        }}>
          <Image
            src={"https://upload.wikimedia.org/wikipedia/vi/8/8b/Logo_Petrovietnam.svg"}
            width={"100%"}
            height={"100%"}
            style={{
              marginBottom: "20px",
            }}
            preview={false}
          />
        </div>

      </div>
      <h3
        style={{
          textAlign: "center",
          fontSize: 18,
          marginBottom: 22,
          marginTop: 15,
          fontWeight: 500,
          color: "rgb(51, 51, 51)",
          padding: "0px 15px"
        }}
      >
        Tập Đoàn Dầu Khí Việt Nam | PVFC
      </h3>

      <Form
        name="login"
        autoComplete="off"
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onFinish(values);
        }}
        className="style_login"
      >
        <Form.Item
          name="phone"
          label={('Số điện thoại')}
          rules={[
            {
              required: true,
              message: ('Số điện thoại không để trống'),
            },
          ]}
        >
          <div className="login__input__icon">
            <PhoneOutlined className="iconLeftInput" />
            <Input
              className="login__input"
              placeholder={('Nhập số điện thoại')}
              type="text"
              size="large"
              onChange={(e) => setState({ ...state, phone: e.target.value })}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="password"
          label={('Mật khẩu')}
          rules={[
            {
              required: true,
              message: ('Mật khẩu không được để trống'),
            },
          ]}
        >
          <div className="login__input__icon">
            <LockOutlined className="iconLeftInput" />
            <Input
              className="login__input"
              type={showPassword ? "password" : "text"}
              placeholder={('Nhập mật khẩu')}
              size="large"
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />
            <div onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <EyeInvisibleOutlined className="eyeIcon" />
              ) : (
                <EyeOutlined className="eyeIcon" />
              )}
            </div>
          </div>
        </Form.Item>
        <div className="extra" onClick={() => handleClick()}>
          {("Bạn chưa có tài khoản? Đăng kí")}
        </div>
        <div className="style_buton">
          <Button
            className="login-btn"
            htmlType="submit"
            loading={loading}
            size="large"
          >
            <Typography.Text style={{ color: "#fff" }}>
              {('Đăng nhập')}
            </Typography.Text>
          </Button>
        </div>

        {/* <div className="style_buton">
          <Button
            onClick={() => history.push("/auth/signup")}
            className="login-btn"
            loading={loading}
            size="large"
          >
            <Typography.Link style={{ color: "#fff" }}>{('Đăng kí')}</Typography.Link>
          </Button>
        </div> */}
      </Form>
    </div>
  );
};
