/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useCallback } from "react";
import { Typography, Spin, message, Form, Input, Button, Select } from "antd";
import axios from "axios";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import Compress from "react-image-file-resizer";
import converter from "../../utils/converterBase64ToBinary";
import "../../css/screens/Verify/verify.scss";
import { BASE_URL } from "../../utils/constant";
import InputMask from "react-input-mask";
import api from "../../api";
import moment from "moment";
import { isNotEmptyString } from "../../utils/isEmptyString";
import { useIntl } from "react-intl";

const Camera = ({ type, setImage, image, profile, isVerified }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  function onChange(event) {
    if (event.target && event.target.files[0]) {
      Compress.imageFileResizer(
        event.target.files[0],
        480,
        480,
        "PNG", // compress format WEBP, JPEG, PNG
        100,
        0, // rotation
        async (uri) => {
          try {
            let formData = new FormData();
            const dataURI = converter(uri);
            formData.append("file", dataURI);
            setLoading(true);
            const { data } = await axios.post(BASE_URL + "/upload", formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            });

            // const transformater = 'w_300,h_400,c_fit';
            // const splited = data.secure_url.split('/');
            // let str = [
            //   ...splited.slice(0, 6),
            //   transformater,
            //   ...splited.slice(6),
            // ];

            const endpoint = data.secure_url;
            localStorage.setItem(type, endpoint);
            setImage(endpoint);
          } catch (err) {
            message.error("UserInfo.label1");
          } finally {
            setLoading(false);
          }
        },
        "base64" // blob or base64 default base64
      );
    }
  }

  return (
    <motion.div
      className="image-container"
      style={{
        backgroundImage: `url(${image})`,
        position: "relative",
      }}
      whileTap={{ scale: 0.98, opacity: 0.3 }}
      onClick={() => !image && document.getElementById(`camera${type}`).click()}
    >
      {image && isVerified !== 1 ? (
        <DeleteOutlined
          style={{
            padding: "8px",
            backgroundColor: "#FFF",
            borderRadius: "8px",
            position: "absolute",
            top: "8px",
            right: "8px",
            fontSize: "16px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setImage(null);
          }}
        />
      ) : (
        ""
      )}
      {loading ? (
        <Spin spinning />
      ) : (
        <>
          {!image && (
            <CameraOutlined
              style={{ fontSize: 30, color: image ? "#fff" : "#333" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={onChange}
            id={`camera${type}`}
            hidden
          />

          {type == "front" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {"UserInfo.label2"}
            </Typography.Text>
          )}
          {type == "back" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {"UserInfo.label3"}
            </Typography.Text>
          )}
          {type == "face" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>{"UserInfo.label4"}</div>
              <div>{"UserInfo.label5"}</div>
            </Typography.Text>
          )}
        </>
      )}
    </motion.div>
  );
};

export default ({ handleCallApi, profile, isVerified }) => {
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [face, setFace] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    setFront(profile?.id_front);
    setBack(profile?.id_back);
    setFace(profile?.id_with_face);
    form.setFieldsValue({
      address: profile?.address,
      dob: profile?.dob,
      id_number: profile?.id_number,
      name: profile?.name,
      sex: profile?.sex,
      job: profile?.job,
      income: profile?.income,
      purpose: profile?.purpose,
    });
  }, [profile]);

  const onFinish = async (values) => {
    // if (!front || !back || !face) {
    //   message.error(('UserInfo.label6'));
    //   return;
    // }

    if (
      +moment().format("YYYY") - parseInt(values["dob"].split("/")[2]) <=
      18
    ) {
      message.error("Số tuổi lớn hơn 18");
      return;
    }
    if (profile?.name !== values.id_number) {
      const { data } = await api.get(`/users/checkId?id=${values.id_number}`);
      if (data.data) {
        message.error("Đã cập nhật thông tin");
        return;
      }
    }

    let _personalInfo = {
      ...values,
      // id_front: front,
      // id_back: back,
      // id_with_face: face,
    };
    console.log(_personalInfo);
    const verified = {};
    if (
      Object.values(_personalInfo).length >= 7 &&
      Object.values(_personalInfo).indexOf("") < 0
    ) {
      verified.verifiedPersonalInfo = 1;
    }

    handleCallApi(_personalInfo, 1, verified);
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="user-img-container"
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ width: "100%", marginTop: "12px" }}
        onFinish={onFinish}
      >
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Họ tên"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không để trống",
            },
          ]}
          name="name"
        >
          <Input
            size="large"
            placeholder={"Không để trống"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.name)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Ngày tháng năm sinh"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không để trống",
            },
          ]}
          name="dob"
        >
          <InputMask
            mask="99/99/9999"
            maskPlaceholder="dd/mm/yyyy"
            placeholder={""}
            className="mask-focus mask-input-date"
            disabled={isNotEmptyString(profile?.dob)}
          />
        </Form.Item>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> CCCD / Thẻ cư trú
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
          name="id_number"
        >
          <Input
            type="number"
            size="large"
            placeholder={"Nhập CCCD / Thẻ cư trú"}
            // suffix={<PicRightOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.id_number)}
          />
        </Form.Item>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Giới tính"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Giới tính không để trống",
            },
          ]}
          name="sex"
        >
          <Select
            size="large"
            placeholder={"Giới tính không để trống"}
            style={{
              borderRadius: 5,
              minWidth: "100%",
              fontSize: 16,
            }}
            disabled={isNotEmptyString(profile?.sex)}
          >
            <Select.Option value="Nam">{"Nam"}</Select.Option>
            <Select.Option value="Nữ">{"Nữ"}</Select.Option>
            <Select.Option value="Khác">{"Khác"}</Select.Option>
          </Select>
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Địa chỉ"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Địa chỉ không để trống",
            },
          ]}
          name="address"
        >
          <Input
            size="large"
            placeholder={"Địa chỉ không để trống"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.address)}
          />
        </Form.Item>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Công việc hiện tại"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Công việc không để trống",
            },
          ]}
          name="job"
        >
          <Input
            size="large"
            placeholder={"Công việc hiện tại"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.job)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Thu nhập hiện tại"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không để trống",
            },
          ]}
          name="income"
        >
          <Input
            size="large"
            placeholder={"Thu nhập hiện tại"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.income)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Mục đích vay"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không để trống",
            },
          ]}
          name="purpose"
        >
          <Input
            size="large"
            placeholder={"Mục đích vay"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.purpose)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Họ tên người tham chiếu"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không để trống",
            },
          ]}
          name="contact1Name"
        >
          <Input
            size="large"
            placeholder={"Họ tên người tham chiếu"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact1Name)}
          />
        </Form.Item>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Số điện thoại người tham chiếu"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không để trống",
            },
          ]}
          name="contact1PhoneNumber"
        >
          <Input
            size="large"
            placeholder={"SĐT tham chiếu"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact1PhoneNumber)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {"Quan hệ người tham chiếu"}
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Không để trống",
            },
          ]}
          name="contact1Relationship"
        >
          <Input
            size="large"
            placeholder={"Quan hệ người tham chiếu"}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact1Relationship)}
          />
        </Form.Item>
        {/* <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> {('UserInfo.label20')}
        </p> */}
        {/* <Form.Item
          rules={[
            {
              required: true,
              message: ('UserInfo.label21'),
            },
          ]}
          name="id_number"
        >
          <Input
            type="number"
            size="large"
            placeholder={('UserInfo.label21')}
            // suffix={<PicRightOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.id_number)}
          />
        </Form.Item> */}

        {/* <p style={{ marginBottom: "4px", fontSize: "16px", textAlign: "left" }}>
          <span style={{ color: "red" }}>*</span> {('UserInfo.label22')}
        </p> */}
        {/* <Camera
          type="front"
          setImage={setFront}
          image={front}
          profile={profile}
          isVerified={isVerified}
        />
        <Camera type="back" setImage={setBack} image={back} profile={profile} isVerified={isVerified}/>
        <Camera type="face" setImage={setFace} image={face} profile={profile} isVerified={isVerified}/> */}

        <motion.div
          whileTap={{ opacity: 0.4, scale: 0.97 }}
          className="confirm-div"
        >
          <Button
            htmlType="submit"
            style={{ background: "transparent", border: "none" }}
          >
            <Typography.Text
              style={{ color: "#fff", fontWeight: 700, fontSize: 17 }}
            >
              {"Tiếp tục"}
            </Typography.Text>
          </Button>
        </motion.div>
      </Form>
    </motion.div>
  );
};
