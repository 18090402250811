import React, { useState, useCallback } from "react";
import { motion } from "framer-motion";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Typography,
  Input,
  Table,
  Modal,
  Popconfirm,
  message,
  InputNumber,
  Select,
  Button,
} from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/auth";
import api from "../../api";
import connectCSKH from "../../utils/connectCSKH";
import "../../css/screens/Vay/Vay.scss";
import { months } from "moment";
import { MONEY_LOAN, LOAN_RATE } from "../../utils/constant";
import { currencyFormatVND } from "../../utils/CurrencyFormat";
import { useIntl } from "react-intl";
// import CurrencyInput from 'react-currency-input-field';

const { Option } = Select;

export default function Vay() {
  const intl = useIntl();

  const [verify, setVerify] = useState("confirm");
  const monthData = [6, 12, 18, 24, 36, 48, 60, 72];
  const dataCall = [
    {
      value: "Thời gian nhận cuộc gọi bất cứ lúc nào",
      label: "Thời gian nhận cuộc gọi bất cứ lúc nào",
    },
    {
      value: "Nhận cuộc gọi vào buổi sáng",
      label: "Nhận cuộc gọi vào buổi sáng",
    },
    {
      value: "Nhận cuộc gọi vào buổi trưa",
      label: "Nhận cuộc gọi vào buổi trưa",
    },
    {
      value: "Nhận cuộc gọi vào buổi chiều",
      label: ("Nhận cuộc gọi vào buổi chiều"),
    },
  ];
  const [selectTimeToCall, setSelectTimeToCall] = useState(dataCall[0].value);
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [amount, setAmount] = useState(0);
  const [month, setMonth] = useState(6);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [notModal, setNotModal] = useState(false);
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const interestRate = Math.round(amount * 0.02 * month);
  const payback = amount + interestRate;
  const loadProfile = async () => {
    const { data } = await api.get("/users/profile");
    dispatch(actions.initialLogin(data.data));
    dispatch({ type: "UPDATE_KYC", payload: { ...(data?.data?.kyc || {}) } });
  };
  const onConfirm = async () => {
    setSubmitLoading(true)
    const { data } = await api.get("/contracts");
    if (data.data.length > 0) {
      setSubmitLoading(false)
      setNotModal(true);
      return;
    }
    const MIN_AMOUNT = 1000000;
    const MAX_AMOUNT = 500000000;
    if (isNaN(amount) || amount < MIN_AMOUNT) {
      setSubmitLoading(false)
      message.error(`${("Số tiền vay lớn hơn")} ${`${MIN_AMOUNT}`.toLocaleString()}`);
      return;
    }
    if (amount > MAX_AMOUNT) {
      setSubmitLoading(false)
      message.error(`${("Số tiền vay nhỏ hơn")} ${`${MAX_AMOUNT}`.toLocaleString()}`);
      return;
    }
    dispatch({
      type: "DISPATCH_CONTRACT",
      payload: { contract: { times: month, amount } },
    });

    // await api.post('/contracts/createContractImage', {
    //   times: month,
    //   amount: amount,
    // });
    await api.post("/users/updateProfile", {
      selectTimeToCall,
    });
    await loadProfile();
    console.log(data.data)
    setSubmitLoading(false)
    // await api.post('/contracts', {
    //   times: month,
    //   amount: amount,
    // });
    if (profile?.verifiedPersonalInfo && profile?.verifiedContactInfo && profile?.verifiedBankInfo) {
      history.replace("/contracts");
      // history.replace("/wallet");
    } else {
      setSubmitLoading(false)
      // message.error(`${("Tài khoản bạn chưa được xác minh")}`);
      history.push("/verify");
    }
  };

  const handleMonth = (value) => {
    setMonth(value);
    if (value === 6) {
      setOne(true);
      setTwo(false);
      setThree(false);
      setFour(false);
      setFive(false);
      setSix(false);
    }
    if (value === 12) {
      setOne(false);
      setTwo(true);
      setThree(false);
      setFour(false);
      setFive(false);
      setSix(false);
    }
    if (value === 18) {
      setOne(false);
      setTwo(false);
      setThree(true);
      setFour(false);
      setFive(false);
      setSix(false);
    }
    if (value === 24) {
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(true);
      setFive(false);
      setSix(false);
    }
    if (value === 36) {
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
      setFive(true);
      setSix(false);
    }
    if (value === 48) {
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
      setFive(false);
      setSix(true);
    }
  };

  const handleChange = (value) => {
    setSelectTimeToCall(value);
  };

  return (
    <div className="container">
      <div className="header-container header_signup bg-[#141ed2] p-4">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: "#fff" }}
        >
          {("Đăng kí vay")}
        </Typography.Text>
        <div></div>
      </div>

      <div className="vay-body">
        <div className="title-container">
          <Typography.Text className="title">
            {("Đăng kí khoản vay")}
          </Typography.Text>
        </div>

        <div className="note-container text-left">
          <Typography.Text className="note text-left">
            {("Nhập số tiền vay")}
          </Typography.Text>
        </div>

        <div className="input-container ">
          <Input
            className="input-field"
            type="number"
            placeholder={("Nhập số tiền vay")}
            style={{ minWidth: "100%" }}
            onChange={(value) => setAmount(value.target.value)}
          />
        </div>
        <div className="moneyShow-container">
          <Typography.Text className="moneyShow">
            {amount === NaN ? 0 : currencyFormatVND(amount)} {` ${('')}`}
          </Typography.Text>
        </div>
        <div className="month-container" style={{ padding: 10 }}>
          {/* <Typography.Text>Chọn thời hạn vay</Typography.Text> */}
          <span
            className={`${month === 6 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(6)}
          >
            {("6 tháng")}
          </span>
          <span
            className={`${month === 12 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(12)}
          >
            {("12 tháng")}
          </span>
          <span
            className={`${month === 18 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(18)}
          >
            {("18 tháng")}
          </span>
          <span
            className={`${month === 24 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(24)}
          >
            {("24 tháng")}
          </span>
          <span
            className={`${month === 36 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(36)}
          >
            {("36 tháng")}
          </span>
          <span
            className={`${month === 48 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(48)}
          >
            {("48 tháng")}
          </span>
          <span
            className={`${month === 60 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(60)}
          >
            {("60 tháng")}
          </span>
          <span
            className={`${month === 72 ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(72)}
          >
            {("72 tháng")}
          </span>
        </div>
        <div className="select_container">
          <Select
            onChange={handleChange}
            value={selectTimeToCall}
            placeholder={("LoanRegistration.label18")}
            style={{ borderRadius: 20, minWidth: "100%" }}
            name="contactMethod"
            options={dataCall.map((value) => ({
              label: value.label,
              value: value.value,
            }))}
          />
        </div>
      </div>
      <div className="interest">
        <div className="old-debt-text">
          <Typography.Text
            style={{ flex: 2, color: "#666", fontSize: 14, fontWeight: "bold" }}
          >
            {("Số tiền trả mỗi tháng")}
          </Typography.Text>
        </div>
        <div className="old-debt-text">
          <Typography.Text style={{ flex: 2, color: "#3e3e3e", fontSize: 18, fontWeight: 'bold' }}>
            {currencyFormatVND(Math.round((amount) * LOAN_RATE / 100 + (amount / month)))} {` ${('')}`}
          </Typography.Text>
        </div>
        <div className="old-debt-text">
          <Typography.Text
            style={{ flex: 2, color: "#666", fontSize: 14, fontWeight: "bold" }}
          >
            {("Lãi suất")}
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              color: "#3e3e3e",
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            {LOAN_RATE}%
          </Typography.Text>
        </div>
        <div className="old-debt-text">
          <Typography.Link onClick={() => setShowDetail(true)}>
            Chi tiết trả nợ
          </Typography.Link>
        </div>
      </div>
      <div className="btn-container">
        <Button className="confirm-btn" onClick={onConfirm} loading={submitLoading}>
          <Typography.Text className="btn-title">{("Đăng kí")}</Typography.Text>
        </Button>
      </div>

      <ModalDetailPayment
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        times={month}
        amount={amount}
      />
      <>
        <Modal
          title={null}
          footer={null}
          visible={notModal}
          closable={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#141ed2",
              padding: 10,
              borderRadius: "30px"
            }}
          >
            <Typography.Text style={{ color: "#fff", fontSize: 17 }} strong>
              {("Bạn đang có 1 khoản vay đang chờ")}
            </Typography.Text>
          </div>
          <br />
          <Typography.Text
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10
            }}
          >
            {("Liên hệ CSKH")}
          </Typography.Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 30,
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              style={{
                background: "#888",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                padding: "0px 10px",
                padding: 10
              }}
              onClick={() => setNotModal(false)}
            >
              <Typography.Text
                style={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
              >
                {("Đóng")}
              </Typography.Text>
            </motion.div>

            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              style={{
                background: "#141ed2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                padding: "0px 10px",
                padding: 10
              }}
              onClick={connectCSKH}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 400, fontSize: 14 }}
              >
                {("Liên hệ CSKH")}
              </Typography.Text>
            </motion.div>
          </div>
        </Modal>
      </>
    </div>
  );
}
const ModalDetailPayment = ({ visible, onCancel, times, amount }) => {
  const intl = useIntl();

  const array = [...new Array(times)].map((item, index) => ({
    index: index + 1,
    amount: amount / times + amount * LOAN_RATE / 100,
    period: `${moment()
      .add(index + 1, "month")
      .format("DD-MM-YYYY")}`,
  }));
  const columns = [
    {
      title: ("Kì"),
      dataIndex: "index",
      key: "index",
      render: (text) => <Typography.Text>{("Kì")} {text}</Typography.Text>,
    },
    {
      title: ("Số tiền trả"),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <Typography.Text strong>
          {currencyFormatVND(Math.ceil(text))}
        </Typography.Text>
      ),
    },
    {
      title: ("Hạn trả"),
      dataIndex: "period",
      key: "period",
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
  ];
  return (
    <Modal
      visible={visible}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      closeIcon={() => <></>}
    >
      <div style={{ maxHeight: 350, overflowY: "scroll" }}>
        <Table dataSource={array} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};
