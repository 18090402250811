import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { Typography, Divider } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "antd/lib/avatar/avatar";
import api from "../../api";
import * as actions from "../../redux/actions/auth";

export default function DetailProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showBankAccount, setShowBankAccount] = useState(false);
  const { profile } = useSelector((state) => state._auth);
  useEffect(() => {
    const interval = setInterval(async () => {
      const { data } = await api.get("/users/profile");
      dispatch(actions.initialLogin(data.data));
      dispatch({ type: "UPDATE_KYC", payload: { ...(data?.data?.kyc || {}) } });
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <motion.div
      style={{ padding: "10px 15px" }}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 5,
          borderBottom: "1px solid #eee",
        }}
      >
        <motion.div
          whileTap={{ scale: 0.95, x: -10 }}
          onClick={() => history.replace("/me")}
          style={{ padding: 0 }}
        >
          <LeftOutlined style={{ fontSize: 25, color: "#555" }} />
        </motion.div>
        <Typography.Text strong style={{ fontWeight: 700, fontSize: 20 }}>
          Thông tin cá nhân
        </Typography.Text>
        <div></div>
      </div>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Avatar src={profile?.kyc?.avatar || profile?.avatar} size={100} />
        <Typography.Text style={{ fontSize: 17, fontWeight: 700 }}>
          {profile?.phone}
        </Typography.Text>
        <Divider orientation="left">Thông tin </Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Họ tên :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.name}{" "}
            </Typography.Text>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Địa chỉ :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.address}{" "}
            </Typography.Text>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Số CMND/CCCD :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.id_number}{" "}
            </Typography.Text>
          </div>
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Số CMND/CCCD :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.id_number}{" "}
            </Typography.Text>
          </div> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Giới tính :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.sex}{" "}
            </Typography.Text>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Ngày sinh :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.dob}{" "}
            </Typography.Text>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Nghề nghiệp :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.job}{" "}
            </Typography.Text>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Thu nhập :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.income}{" "}
            </Typography.Text>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Mục đích khoản vay :{" "}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.purpose}{" "}
            </Typography.Text>
          </div>
        </div>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px 0px",
          }}
        >
          <Typography.Text
            style={{
              paddingRight: 10,

              fontSize: 14,
              fontWeight: 500,
              flex: 2,
            }}
          >
            SĐT người thân :{" "}
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {profile?.kyc?.relative_number}{" "}
          </Typography.Text>
        </div> */}

        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px 0px",
          }}
        >
          <Typography.Text
            style={{
              paddingRight: 10,

              fontSize: 14,
              fontWeight: 500,
              flex: 2,
            }}
          >
            Mối quan hệ với người thân :{" "}
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {profile?.kyc?.relative}{" "}
          </Typography.Text>
        </div> */}

        <Divider orientation="left">Tài khoản ngân hàng</Divider>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px 0px",
          }}
        >
          <Typography.Text
            style={{
              paddingRight: 10,

              fontSize: 14,
              fontWeight: 500,
              flex: 2,
            }}
          >
            Tên ngân hàng :{" "}
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {profile?.kyc?.bank?.bankName}{" "}
          </Typography.Text>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px 0px",
          }}
        >
          <Typography.Text
            style={{
              paddingRight: 10,

              fontSize: 14,
              fontWeight: 500,
              flex: 2,
            }}
          >
            Số TK ngân hàng :{"  "}
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {showBankAccount ? profile?.kyc?.bank?.number : "**********"}{" "}
          </Typography.Text>
          <div onClick={() => setShowBankAccount(!showBankAccount)}>
            {
              showBankAccount ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>

            }
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px 0px",
          }}
        >
          <Typography.Text
            style={{
              paddingRight: 10,

              fontSize: 14,
              fontWeight: 500,
              flex: 2,
            }}
          >
            Tên thụ hưởng :{" "}
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {profile?.kyc?.bank?.name}{" "}
          </Typography.Text>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px 0px",
          }}
        >
          <Typography.Text
            style={{
              paddingRight: 10,

              fontSize: 14,
              fontWeight: 500,
              flex: 2,
            }}
          >
            ID chủ thẻ :{" "}
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {profile?.kyc?.bank?.idbank}{" "}
          </Typography.Text>
        </div>
      </div>
    </motion.div>
  );
}
