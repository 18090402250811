import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  WifiOutlined,
  PayCircleOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { Typography, Modal, message, Image, Input, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import _cardbg from "../../assets/card.png";
// import card_logo1 from "../../assets/card_logo1.jpg";
// import card_logo2 from "../../assets/card_logo2.jpg";
// import card_logo3 from "../../assets/card_logo3.jpg";
// import card_logo4 from "../../assets/card_logo4.jpg";
// import card_logo5 from "../../assets/card_logo5.jpg";
// import card_logo6 from "../../assets/card_logo6.jpg";
import api from "../../api";
import useNotification from "../../hooks/useNotification";
import * as actions from "../../redux/actions/auth";
import connectCSKH from "../../utils/connectCSKH";
import CurrencyInput from "react-currency-input-field";
import "../../css/screens/Wallet/Wallet.scss";
import moment from "moment";
import _ from "lodash";
import { currencyFormatVND } from "../../utils/CurrencyFormat";
import { useIntl } from "react-intl";
const LOAN_FEE_RATE = 5;
function getIntValue(value) {
  if (isNaN(value)) {
    return 0;
  }
  if (!value) {
    return 0;
  }
  return value * 1;
}
export default function Wallet() {
  const { profile } = useSelector((state) => state._auth);
  const { notifications, pushNotifications } = useNotification();
  const dispatch = useDispatch();
  const [currentRequest, setCurrentRequest] = useState({});
  const [OTP, setOTP] = useState("");
  const [visibleOTP, setVisibleOTP] = useState(false);
  const [systemConfig, setSystemConfig] = useState({});
  async function getSystemConfig() {
    const { data } = await api.get("/systemConfiguration/getSystemConfig");
    setSystemConfig(data.data);
  }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadProfile();
    getSystemConfig();
    loadContract();
  }, [notifications]);

  const loadProfile = async () => {
    const { data } = await api.get("/users/profile");
    dispatch(actions.initialLogin(data.data));
    dispatch({ type: "UPDATE_KYC", payload: { ...(data?.data?.kyc || {}) } });
    loadCurrentRequest();
    console.log(profile?.kyc?.bank?.bankCardImageUrl);
  };

  const loadCurrentRequest = async () => {
    try {
      const { data } = await api.get("/requests/lasted");
      setCurrentRequest(data?.data ? data.data : {});
    } catch (error) {
      setCurrentRequest({});
      console.log(error);
    }
  };

  const [contract, setContract] = useState({});
  const [visible, setVisible] = useState(false);
  const [isDone, setIsDone] = useState(false);
  //const [amountToRequest, setAmountToRequest] = useState(0);

  const onConfirmWithdraw = async (e) => {
    if (!_.isEmpty(currentRequest) && currentRequest?.status !== 'accepted') {
      setIsDone(false)
      setVisible(true)
      return;
    }

    if (profile.balance === 0) {
      message.error("Số dư hiện tại là 0");
      return;
    }
    handleRequest(e);
    //setAmountToRequest(e);
    // setVisibleOTP(true);
  };

  async function loadContract() {
    const contracts = await api.get("/contracts");
    if (contracts.data.data[0]) {
      setContract(contracts.data.data[0]);
    }
  }

  async function handleRequest(amountToRequest) {
    setLoading(true);
    await loadProfile();

    // setOTP("")
    // if (+profile?.otp !== +OTP) {
    //   message.error(('OTP không đúng'));
    //   return;
    // }

    const contracts = await api.get("/contracts");

    if (contracts.data.data[0]) {
      const requestResult = await api.post("/requests", {
        amount: amountToRequest,
        contractId: contracts.data.data[0]._id,
        bank_reciever: {
          name: profile?.kyc?.bank?.name,
          number: profile?.kyc?.bank?.number,
          bankName: profile?.kyc?.bank?.bankName,
        },
      });
      setContract(requestResult.data);
      pushNotifications({
        message: `${profile.phone} ${"đã rút tiền"}`,
        description: `${"Đã rút"} ${profile.balance.toLocaleString()}`,
      });
      loadProfile();
      setVisible(true);
      setIsDone(requestResult.data.response === "accepted");
      setLoading(false);
      return requestResult;
    } else {
      message.error("Wallet.label6");
      setLoading(false);
      return;
    }
  }
  const renderStatus = (status) => {
    switch (status) {
      case "pending":
        return "Đang xử lý";
      case "accepted":
        return "Đã chấp nhận";
      case "rejected":
        return "Từ chối";
      case "onhold":
        return "Cảnh báo";
    }
    return "";
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      {loading && (
        <div className="loading">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
        </div>
      )}

      <div className="header-container header_signup bg-[#141ed2] p-4">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: "#fff" }}
        >
          {"Ví tiền"}
        </Typography.Text>
        <div></div>
      </div>
      <div style={{ padding: "4px 10px" }}>
        <Card
          data={profile}
          balance={profile?.balance}
          onWithdraw={(e) => onConfirmWithdraw(e)}
          currentRequest={currentRequest}
        />
        <motion.div
          whileTap={{ scale: 0.97, opacity: 0.3 }}
          className={
            currentRequest && currentRequest.status === "pending"
              ? "disabled_custom"
              : ""
          }
        >
          <Item
            text={"Rút tiền về tài khoản liên kết"}
            icon={<PayCircleOutlined className="pay-circle" />}
            onClick={
              // profile?.kyc?.status
              // ?
              () => onConfirmWithdraw(profile?.balance)
              //() => setVisible(true)
              // : () => message.info(('Wallet.label9'))
            }
          />
        </motion.div>
        {!_.isEmpty(currentRequest) && (
          <div className="request-detail">
            <div className="title-detail">{"Thông tin rút tiền"}</div>
            <table>
              <tr>
                <td>{"Thời gian"}</td>
                <td>
                  {moment(currentRequest.created_at).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </td>
              </tr>
              <tr>
                <td>{"Số tiền rút"}</td>
                <td>
                  {currentRequest.amount
                    ? currentRequest.amount.toLocaleString() + " " + ""
                    : ""}
                </td>
              </tr>
              <tr>
                <td>{"Trạng thái"}</td>
                <td>{renderStatus(currentRequest.status)}</td>
              </tr>
              <tr>
                <td>{"Ghi chú"}</td>
                <td>{currentRequest.error}</td>
              </tr>
            </table>
          </div>
        )}
      </div>
      <div class="grid-container">
        <div class="grid-item !rounded-none">
          <img
            class="grid-item-image"
            src={"/image/wallet.911a2f9edd9a6151a551.jpg"}
          ></img>
        </div>
        {/* <div class="grid-item"><img class="grid-item-image" src={card_logo1}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo2}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo3}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo4}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo5}></img></div>
  <div class="grid-item"><img class="grid-item-image" src={card_logo6}></img></div> */}
      </div>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        centered
        closable={true}
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
      >
        <div className="verifying">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {isDone ? (
              <>
                <CheckOutlined style={{ color: "#40eb31", fontSize: 40 }} />
                <Typography.Text
                  style={{
                    color: "#333",
                    fontSize: 19,
                    textAlign: "center",
                    paddingVertical: 10,
                  }}
                >
                  Rút tiền thành công
                </Typography.Text>
                <Typography.Text
                  style={{ color: "#777", fontSize: 15, textAlign: "center" }}
                >
                  lệnh rút tiền sẽ được thực hiện. Vui lòng chờ tiền về STK đã liên kết! Thời gian dự kiến 30 phút
                </Typography.Text>
              </>
            ) : (
              <>
                {currentRequest?.status == "pending" ? (
                  <>
                    <WarningOutlined
                      style={{ fontSize: 50, color: "orange" }}
                    />
                    <Typography.Text
                      style={{
                        color: "#777",
                        fontSize: 17,
                        color: "orange",
                        textAlign: "center",
                        padding: 10,
                      }}
                    >
                      Đang chờ rút tiền tiền
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: "#777", textAlign: 'center', fontSize: 17 }}
                    >
                      {currentRequest?.error}
                    </Typography.Text>
                  </>
                ) : (
                  <>
                    <ExclamationCircleOutlined
                      style={{ fontSize: 50, color: "#eb314a" }}
                    />
                    <Typography.Text style={{ color: "#777", fontSize: 17 }}>
                      Từ chối yêu cầu
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: "#777", fontSize: 17, color: "#eb314a" }}
                    >
                      {currentRequest?.error}
                    </Typography.Text>
                  </>
                )}

                <Typography.Text style={{ color: "#777", fontSize: 17 }}>
                  Liên hệ CSKH trực tiếp để được hổ trợ
                </Typography.Text>
                <br />

                <motion.div
                  whileTap={{ opacity: 0.4, scale: 0.97 }}
                  style={{
                    background: "#141ed2",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    padding: 5,
                  }}
                  onClick={connectCSKH}
                >
                  <Typography.Text
                    style={{ fontSize: 17, color: "#fff", fontWeight: "bold" }}
                  >
                    Ấn vào đây để liên hệ CSKH
                  </Typography.Text>
                </motion.div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        visible={visibleOTP}
        title={"Rút tiền"}
        footer={null}
        closable={true}
        onCancel={() => setVisibleOTP(false)}
        destroyOnClose
      >
        <div className="withdraw-money-container">
          {/* <p>{("Wallet.label35")}</p>
          <p>{("Wallet.label36")}</p>
          <p>{("Wallet.label37")}</p>
          <p>{(contract && contract.amount > 0)
            ? ("Wallet.label38").replace('{feeAmount}', contract.amount * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', contract.amount).replace('{totalAmount}', contract.amount + contract.amount * LOAN_FEE_RATE / 100)
            : ("Wallet.label38").replace('{feeAmount}', 0 * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', 0).replace('{totalAmount}', 0 + 0 * LOAN_FEE_RATE / 100)
          }</p>
          <p>{(contract && contract.amount > 0)
            ? ("Wallet.label39").replace('{feeAmount}', contract.amount * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', contract.amount).replace('{totalAmount}', contract.amount + contract.amount * LOAN_FEE_RATE / 100)
            : ("Wallet.label39").replace('{feeAmount}', 0 * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', 0).replace('{totalAmount}', 0 + 0 * LOAN_FEE_RATE / 100)
          }</p>
          <p>{(contract && contract.amount > 0)
            ? ("Wallet.label40").replace('{feeAmount}', contract.amount * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', contract.amount).replace('{totalAmount}', contract.amount + contract.amount * LOAN_FEE_RATE / 100)
            : ("Wallet.label40").replace('{feeAmount}', 0 * LOAN_FEE_RATE / 100).replace('{feeRate}', LOAN_FEE_RATE).replace('{loanAmount}', 0).replace('{totalAmount}', 0 + 0 * LOAN_FEE_RATE / 100)
          }</p> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                window.open(`${systemConfig?.telegramUrl}`, "_blank")
              }}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 700, fontSize: 16, textAlign: 'center' }}
              >
                {('Wallet.label41')}
              </Typography.Text>
            </motion.div>
          </div> */}
          <p></p>
          {/* <Input
            className="input-currency"
            placeholder={('Wallet.label21')}
            value={OTP}
            onChange={(e) => setOTP(e.target.value)}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                // setVisibleOTP(false);
                handleRequest();
              }}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 700, fontSize: 16 }}
              >
                {"Gửi"}
              </Typography.Text>
            </motion.div>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}

function Card({ data, balance, onWithdraw, currentRequest }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState(0);
  const [showMoney, setShowMoney] = useState(true);
  const [showBankAccount, setShowBankAccount] = useState(false);
  const intl = useIntl();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {/* <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "4px" }}>
        {('Wallet')}
      </p> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data?.kyc?.bank?.bankCardImageUrl ? (
          <img
            src={data?.kyc?.bank?.bankCardImageUrl}
            style={{
              borderRadius: 5,
              width: "100%",
              height: 250,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          />
        ) : (
          <div
            style={{
              borderRadius: 5,
              width: "100%",
              height: 250,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              backgroundImage: `url(${"/image/card.d49cd7cf12be322a95cb.png"})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="card-head-img">
              <div style={{}}>
                {/* {data?.kyc?.bank?.logo ? (
                <Image
                  src={data?.kyc?.bank?.logo}
                  width="50%"
                  preview={false}
                />
              ) : ( */}
                <WifiOutlined
                  style={{
                    fontSize: 26,
                    color: "#fff",
                    fontWeight: "bold",
                    transform: "rotate(90deg)",
                  }}
                />
                <Typography.Text
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    color: "#fff",
                    padding: "0px 5px",
                  }}
                >
                  {data?.kyc?.bank?.bankName}
                </Typography.Text>
                {/* )} */}
              </div>
            </div>

            <div
              style={{
                padding: 10,
                justifyContent: "flex-start",
                minWidth: "100%",
              }}
            >
              <div
                className="atm-card-information"
                style={{ position: "relative" }}
              >
                {data?.kyc?.bank?.number ? (
                  <>
                    <div
                      style={{ position: "absolute", top: 0, right: "10px" }}
                    >
                      {showBankAccount ? (
                        <EyeOutlined
                          onClick={() => setShowBankAccount((prev) => !prev)}
                          className="eye-icon !text-[#fff]"
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() => setShowBankAccount((prev) => !prev)}
                          className="eye-icon !text-[#fff]"
                        />
                      )}
                    </div>

                    <Typography.Text className="atm-card-text">
                      {data?.kyc?.bank?.number
                        ? showBankAccount
                          ? `${data.kyc.bank.number}`
                          : `********` +
                          data.kyc.bank.number.substring(
                            data.kyc.bank.number.length - 4
                          )
                        : ""}
                    </Typography.Text>
                    <Typography.Text className="atm-card-text">
                      {data?.kyc?.bank?.name}
                    </Typography.Text>
                  </>
                ) : (
                  <Typography.Text className="atm-card-text">
                    {"Chưa đăng kí"}
                  </Typography.Text>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <p style={{ margin: "4px 0", color: "#1890ff", fontSize: "12px" }}>
        {('Ví')}
      </p> */}
      <motion.div
        className="check-amount-container flex justify-between"
      // onClick={() => {
      //   if (currentRequest && currentRequest.status === "pending") {
      //     setVisible(true);
      //     return;
      //   }
      //   setVisible(true); // bỏ sau
      // }}
      >
        <motion.div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px"
          }}
          whileTap={{ opacity: 0.3 }}
        >
          <Typography.Text
            style={{ fontSize: 15, color: "#333", fontWeight: "600" }}
          >
            {"Số dư ví"}
          </Typography.Text>
          {showMoney ? (
            <EyeOutlined
              onClick={(e) => {
                setShowMoney((prev) => !prev);
                e.stopPropagation();
              }}
              className="eye-icon"
            />
          ) : (
            <EyeInvisibleOutlined
              onClick={(e) => {
                setShowMoney((prev) => !prev);
                e.stopPropagation();
              }}
              className="eye-icon"
            />
          )}
        </motion.div>
        <div>
          <Typography.Text
            style={{
              fontSize: 17,
              color: "#141ed2",
              fontWeight: 700,
              display: "block",
            }}
          >
            {showMoney
              ? `${currencyFormatVND(getIntValue(data?.balance))}  ${""}`
              : "******"}{" "}
          </Typography.Text>
        </div>
      </motion.div>
      <span
        onClick={() => history.push("/history")}
        style={{ textDecoration: "underline", margin: 5 }}
      >
        Biến động số dư
      </span>
      <Modal
        visible={visible}
        title={"Rút tiền"}
        footer={null}
        closable={true}
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <div className="withdraw-money-container">
          <CurrencyInput
            className="input-currency"
            min={0}
            max={balance}
            placeholder={"Nhập số tiền rút"}
            decimalsLimit={2}
            onValueChange={(value, name) => setAmount(parseInt(value))}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                if (amount > data?.balance) {
                  message.info("Số tiền rút không được lớn hơn số dư trong ví");
                  return;
                }

                setVisible(false);
                onWithdraw(amount);
              }}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 700, fontSize: 16 }}
              >
                {"Rút tiền"}
              </Typography.Text>
            </motion.div>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}
const Item = ({ text = "title", icon, onClick = () => { } }) => {
  return (
    <div
      className="item"
      onClick={onClick}
      style={{ background: "rgb(20, 30, 210)", borderRadius: 50 }}
    >
      <PayCircleOutlined style={{ fontSize: 22, color: "#fff" }} />
      <Typography.Text
        style={{ flex: 1, fontSize: 16, paddingLeft: 20, color: "#fff" }}
      >
        {text}
      </Typography.Text>
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="vertical-align-bottom"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
        className="text-[#fff] text-[20px]"
      >
        <path d="M859.9 780H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM505.7 669a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V176c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8z" />
      </svg>
    </div>
  );
};
