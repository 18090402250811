import React, { useState, useEffect, useCallback } from "react";
import { Badge, Typography, Carousel, Image, Button } from "antd";
import { AlertFilled, DashboardFilled, SlidersFilled } from "@ant-design/icons";
// import tickAsset from '../../assets/tick.png';
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

// import home from '../../assets/banner.jpg';
import "../../css/screens/Home/Home.scss";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Notification from "../../components/Notification";

export default function Home() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const intl = useIntl();

  const arrayText = [
    "037**** đã rút 20.000.000 ",
    "098**** đã rút 50.000.000 ",
    "036*** đã rút 10.000.000 ",
    "090*** đã rút 12.300.000 ",
    "037**** đã rút 20.000.000 ",
    "090**** đã rút 25.500.000 ",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1)
        setCurrentIndex((prev) => prev + 1);
      else setCurrentIndex((prev) => 0);
    }, 1800);
    return () => clearInterval(interval);
  });
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content  !bg-[#141ed2]] p-4">
        <div>
          <Typography.Text className="text-head">{"Xin chào"}</Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>
        <div
          style={{ marginLeft: "auto", paddingRight: 20, textAlign: "right" }}
        >
          <span className="ant-typography text-head">PVFC</span> <br />
          <span className="ant-typography text-head">Kính chào quý khách</span>
        </div>

        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push("/notifications")}
        >
          <Notification />
        </motion.div>
      </div>

      <div className="noti-text-container">
        <Typography.Text className="noti-text">
          {arrayText[currentIndex]}
        </Typography.Text>
      </div>
      {/* <Carousel autoplay className="carousel-container">
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container !w-full !p-0">
              <Image
                src={"/image/133346564916001774_thumb4.jpg"}
                width={"100%"}
                preview={false}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container !w-full !p-0">
              <Image
                src={"/image/maxresdefault.jpg"}
                width={"100%"}
                preview={false}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container !w-full !p-0">
              <Image
                src={"/image/133481251567379236_thum.jpg"}
                width={"100%"}
                preview={false}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container !w-full !p-0">
              <Image
                src={"/image/133481251567379236_thum.jpg"}
                width={"100%"}
                preview={false}
              />
            </div>
          </div>
        </div>
      </Carousel> */}
      <div style={{
        padding: "0 10px"
      }}>
        <img src="/image/photo_2024-10-14 21.00.05.jpeg" style={{
          maxHeight: "450px",
          height: "100%",
          width: "100%"
        }} />
      </div>
      <Button
        className="choose-btn"
        onClick={() => {
          history.push("/vay");
        }}
      >
        <Typography.Text
          style={{ color: "#fff", fontWeight: "bold", fontSize: 20 }}
        >
          {"Đăng kí khoản vay"}
        </Typography.Text>
      </Button>
      <div style={{ padding: 20 }}>
        <Tab
          title={"Thủ tục vay nhanh chóng đơn giản"}
          icon={<AlertFilled style={{ fontSize: 20, color: "#ff7323" }} />}
        />
        <Tab
          title={"hạn mức vay lên đến 500tr "}
          icon={<DashboardFilled style={{ fontSize: 20, color: "#008f50" }} />}
        />
        <Tab
          title={"Nhận tiền chỉ sau 30 phút làm hồ sơ"}
          icon={<SlidersFilled style={{ fontSize: 20, color: "#141ed2" }} />}
        />
      </div>
      <Carousel autoplay className="carousel-container">
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container !w-full !p-0">
              <Image
                src={"/image/133026053795467438_Thumbnail.jpg"}
                width={"100%"}
                preview={false}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container !w-full !p-0">
              <Image
                src={
                  "/image/133113326628415613_Mcredit.1%20ok%20840x468%20(1).jpg"
                }
                width={"100%"}
                preview={false}
              />
            </div>
          </div>
        </div>
        {/* <div>
          <div className="carousel slider4 !w-full !p-0">
            <div className="sl-text-container">
              <Image src={"/image/banner2.jpeg"} width={'100%'} preview={false} />
            </div>
          </div>
        </div> */}
      </Carousel>
      <div className="sl-text-container !w-full p-1">
        <Image src={"/image/iso-2023.jpg"} width={"100%"} preview={false} />
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="flex gap-2 items-center">
          <Image
            src={"/image/tick.e14cb23e178b8d3acd8f34.png"}
            preview={false}
            style={{ maxWidth: 100 }}
          />
          <Image
            src={"/image/tick.e14cb23e178b8d3acd8f.png"}
            preview={false}
            style={{ maxWidth: 100 }}
          />
        </div>

        <Typography.Text
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#555",
            textAlign: "center",
          }}
        >
          &reg;{" "}
          {"Bản quyền thuộc về Tập Đoàn Dầu Khí Việt Nam | PVFC. "}
          <br />
          Tất cả các quyền được bảo hộ.
          <br />
          Copyright © 2023 PVFC
        </Typography.Text>
      </div>
    </motion.div>
  );
}
const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: "1px solid #141ed2",
      }}
      onClick={onClick}
      style={{
        margin: "10px 0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "7px 15px",
        border: "1px solid #141ed2",
        borderRadius: 5,
      }}
    >
      <Typography.Text
        style={{ flex: 1, fontSize: 16, color: "#141ed2", fontWeight: 500 }}
      >
        {title}
      </Typography.Text>
      {icon}
    </motion.div>
  );
};
